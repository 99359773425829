import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const SitemapPage = () => (
    <Layout>
        <Seo title={'Nemo\'s Sitemap'} description={''} lang={'en-gb'} />
        <div className="oneCol purple cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">


                    <div className="headingArea cfx">

                        <div className="bubble purple cfx cp">
                            <div className="inr">
                                <h1>Sitemap</h1>
                            </div>
                            <div className="bot"></div>
                        </div>

                        <div className="intro">
                            <p className="leading"></p>
                        </div>
                    </div>


                    <div className="sitemap purple">
                        <h2>Existing Customers</h2>
                        <ul>
                            <li>
                                <a href="/about-us">About us</a>
                            </li>
                            <li>
                                <a href="/contact-us">Contact us</a>
                                <ul>
                                    <li>
                                        <a href="/contact-us/complaints">Nemo Personal Finance Complaints Procedure</a>
                                    </li>
                                </ul>
                            </li>
                            <li><
                                a href="/customer-service">Customer Service</a>
                                <ul>
                                    <li><a href="/customer-service/moving-home">How your Nemo
                                        mortgage works</a></li>
                                    <li><a href="/customer-service/remortgaging">What to do if
                                        you want to remortgage </a></li>
                                    <li><a
                                        href="/customer-service/settle-my-second-charge-mortgage">Settle
                                        my Nemo mortgage early</a></li>
                                    <li><a href="/customer-service/update-my-details">Update my
                                        details</a></li>
                                </ul>
                            </li>
                            <li><a href="/help-with-repayments">Help with repayments</a>
                                <ul>
                                    <li><a
                                        href="/help-with-repayments/dealing-with-difficult-times">Dealing
                                        with difficult times</a></li>
                                    <li><a href="/help-with-repayments/useful-information">Useful
                                        information</a></li>
                                    <li><a href="/help-with-repayments/budget-planner-intro">Budget
                                        Planner</a></li>
                                </ul>
                            </li>
                            <li><a
                                href="/faq">Help &amp; FAQs</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bdrBot"></div>
            </div>
        </div>
    </Layout>
);

export default SitemapPage;